<template>
  <div class="mainArea">
    <van-list
      v-model="dataParams.loading"
      :error.sync="dataParams.error"
      :finished="dataParams.finished"
      :immediate-check="false"
      error-text="请求失败，点击重新加载"
      offset="300"
      @load="onLoad"
    >
      <!-- finished-text="已加载完毕" -->
      <div class="vanBox">
        <div
          v-for="(item, index) in dataList"
          :key="index"
          class="boxCell"
          @click="gotoBuy(item)"
        >
          <img :src="item.productCover" alt="">
          <p class="everyTitle van-multi-ellipsis--l2">
            {{ item.productName }}
          </p>
          <div class="saleBox">
            <div class="saleIcon">{{ item.discount }}折</div>
            <div class="saleValue">
              <img src="../../assets/images/feng-ye.png" alt="">
              <span class="saleValue_text">成长值 {{ item.growthValue }}</span>
            </div>
          </div>
          <div class="priceBox">
            <div class="newPrice"><span>￥</span>{{ item.vipPrice }}</div>
            <div class="oldPrice">￥{{ item.originPrice }}</div>
          </div>

          <div class="buyBtn">立即抢购</div>
        </div>
        <van-empty v-if="dataList.length === 0" description="暂无数据" />
      </div>
    </van-list>
    <van-empty v-if="dataList.length > 0" description="已加载完毕" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Empty } from 'vant'

Vue.use(Empty)
export default {
  name: 'ClothesList',
  props: {
    dataList: {
      type: null,
      default: null
    },
    dataParams: {
      type: null,
      default: null
    },
    env: {
      type: null,
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {
    onLoad() {
      this.$nextTick(() => {
        this.$emit('sendPage', { nowPage: this.dataParams.page + 1 })
      })
    },

    gotoBuy(obj) {
      obj.goodId = obj.productId
      obj.skuId = obj.productSkuId
      obj.room_id = this.$route.query.room_id
      obj.robot_send = this.$route.query.robot_send
      this.$store.dispatch('godetail', obj)
    }
  }
}
</script>

<style scoped lang="less">
.mainArea {
  width: 375px;
  min-height: 420px;
  background: #f8f8f8;
  /*padding:7px 14px 0 14px;*/
}

.vanBox {
  width: 375px;
  min-height: 420px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
}

.boxCell {
  width: 170px;
  height: 320px;
  background: #ffffff;
  border-radius: 7px 0px 0px 7px;
  margin-bottom: 7px;

  img {
    width: 170px;
    height: 170px;
  }
}

.boxCell:nth-child(2n + 1) {
  margin-left: 14px;
}

.boxCell:nth-child(2n) {
  margin-right: 14px;
}

.everyTitle {
  width: 151px;
  height: 33px;
  margin: 6px 13px 0 6px;
  font-size: 13px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.saleBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 9px 13px 0 6px;

  .saleIcon {
    background: url("../../assets/images/yhj_red.png") no-repeat;
    background-size: 50px 17px;
    width: 50px;
    height: 17px;
    text-align: center;
    line-height: 19px;
    font-size: 12px;
    color: #fff;
    /*color: #fefefe;*/
  }

  .saleValue {
    background: #333333;
    border-radius: 0px 6px 6px 3px;
    color: #fff;
    min-width: 75px;
    height: 17px;
    margin-left: 7px;
    line-height: 18px;
    font-size: 12px;
    position: relative;

    img {
      width: 12px;
      height: 13.5px;
      position: absolute;
      top: 2px;
      left: 5px;
    }

    .saleValue_text {
      /*position: absolute;*/
      /*top: 1px;*/
      /*left: 21px;*/
      margin-left: 21px;
      padding-right: 5px;
      /*background-color: chocolate;*/
    }
  }
}

.priceBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 11px 13px 0 6px;
  font-size: 16px;
  /*background-color: darkcyan;*/

  .newPrice {
    color: #f7253b;
    font-weight: 500;

    span {
      font-size: 12px;
      display: inline-block;
      font-family: PingFang SC Medium, PingFang SC Medium-Medium;
      font-weight: 500;
      text-align: left;
      margin-right: 2px;
    }
  }

  .oldPrice {
    margin-left: 6px;
    color: #999999;
    text-decoration: line-through;
  }
}

.buyBtn {
  width: 157px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #f7263c;
  border-radius: 15px;
  color: white;
  margin: 10px 13px 0 6px;
}

/deep/ .van-empty {
  margin: 10px auto;
}
</style>
